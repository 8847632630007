let warnedBefore = false;
const warn = () => {
    if (warnedBefore) {
        return;
    }
    // We will only warn the user if the Badging API is not available at all
    if ('ExperimentalBadge' in window || 'setExperimentalAppBadge' in navigator) {
        return;
    }
    console.warn('Badging API must be enabled. Please check here how you can enable it: https://developers.google.com/web/updates/2018/12/badging-api#use');
    warnedBefore = true;
};
const current = {
    mediaQuery: null,
    value: 0,
};
function isVersion1Available() {
    return 'ExperimentalBadge' in window && !!window.ExperimentalBadge;
}
function isVersion2Available() {
    return ('setExperimentalAppBadge' in navigator &&
        !!navigator.setExperimentalAppBadge &&
        'clearExperimentalAppBadge' in navigator &&
        !!navigator.clearExperimentalAppBadge);
}
function isAvailable() {
    if (!current.mediaQuery) {
        current.mediaQuery = window.matchMedia('(display-mode: standalone)');
        // Get notified once app is installed
        current.mediaQuery.onchange = event => {
            set(current.value);
        };
    }
    return (current.mediaQuery.matches &&
        (isVersion1Available() || isVersion2Available()));
}
function set(value) {
    current.value = value;
    if (!isAvailable()) {
        warn();
        return false;
    }
    // Sets the badge to contents (an integer), or to "flag" if contents is omitted. If contents is 0, clears the badge for the matching app(s).
    // See details here: https://github.com/WICG/badging/blob/master/explainer.md#the-api
    if (isVersion1Available()) {
        window.ExperimentalBadge.set(value);
        return true;
    }
    else if (isVersion2Available()) {
        navigator.setExperimentalAppBadge(value);
        return true;
    }
    return false;
}
function clear() {
    if (!isAvailable()) {
        return;
    }
    if (isVersion1Available()) {
        window.ExperimentalBadge.clear();
    }
    else if (isVersion2Available()) {
        navigator.clearExperimentalAppBadge();
    }
}

function deepMerge(target, source) {
    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object)
            Object.assign(source[key], deepMerge(target[key], source[key]));
    }
    // Join `target` and modified `source`
    Object.assign(target || {}, source);
    return target;
}

function isPositiveNumber(value) {
    return (typeof value !== 'undefined' && Number.isInteger(value) && value >= 0);
}

// Get the current favicon of the document
const getFavicon = () => {
    const links = document.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
        const link = links[i];
        if (link.hasAttribute('href') &&
            (link.getAttribute('rel') || '').match(/\bicon\b/)) {
            return link;
        }
    }
    return null;
};
// Calculate the size of the font and canvas element based on device's ratio
const ratio = Math.ceil(window.devicePixelRatio) || 1;
const size = 16 * ratio;
// Options
const defaultOptions = {
    backgroundColor: '#424242',
    color: '#ffffff',
    indicator: '!',
};
// References to the favicons that we need to track in order to reset and update the counters
const current$1 = {
    favicon: null,
    value: 0,
    options: defaultOptions,
};
let original = null;
let image = null;
// Setup the source canvas element which we use to generate the favicon's data-url's from
let canvas = null;
let context = null;
// Update the favicon
const setFavicon = (url) => {
    if (!url) {
        return;
    }
    // Remove the old favicon tags
    let tag = getFavicon();
    while (tag && tag.parentNode) {
        tag.parentNode.removeChild(tag);
        tag = getFavicon();
    }
    // Create new favicon
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
};
// Draw the favicon
const drawFavicon = (value, options) => {
    if (!image) {
        return;
    }
    image.onload = () => {
        if (!image || !canvas) {
            return;
        }
        // Draw image in canvas
        context.clearRect(0, 0, size, size);
        context.drawImage(image, 0, 0, image.width, image.height, 0, 0, size, size);
        // Draw bubble over the top
        drawBubble(context, value, options);
        // Refresh tag in page
        setFavicon(canvas.toDataURL());
    };
    // Reload image
    if (original) {
        // Allow cross origin resource requests if the image is not a data:uri
        if (!original.href.match(/^data/)) {
            image.crossOrigin = 'anonymous';
        }
        image.src = original.href;
    }
};
// Draws the bubble on the canvas
const drawBubble = (context, value, options) => {
    // Do we need to render the bubble at all?
    let finalValue = '';
    if (isPositiveNumber(value)) {
        if (value === 0) {
            finalValue = '';
        }
        else if (value < 100) {
            finalValue = String(value);
        }
        else {
            finalValue = '99+';
        }
    }
    else {
        finalValue = options.indicator;
    }
    // Return early
    if (!finalValue) {
        return;
    }
    // Calculate position etc.
    const length = finalValue.length - 1;
    const width = 8 * ratio + 4 * ratio * length;
    const height = 7 * ratio;
    const top = size - height;
    const left = size - width - ratio;
    const bottom = 16 * ratio;
    const right = 16 * ratio;
    const radius = 5 * ratio;
    // Bubble
    context.save();
    context.globalAlpha = 1;
    context.fillStyle = options.backgroundColor;
    context.strokeStyle = options.backgroundColor;
    context.lineWidth = ratio;
    context.beginPath();
    context.moveTo(left + radius, top);
    context.quadraticCurveTo(left, top, left, top + radius);
    context.lineTo(left, bottom - radius);
    context.quadraticCurveTo(left, bottom, left + radius, bottom);
    context.lineTo(right - radius, bottom);
    context.quadraticCurveTo(right, bottom, right, bottom - radius);
    context.lineTo(right, top + radius);
    context.quadraticCurveTo(right, top, right - radius, top);
    context.closePath();
    context.fill();
    context.restore();
    // Value
    context.save();
    context.font = `${7 * ratio}px Arial`;
    context.fillStyle = options.color;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    context.fillText(finalValue, left + width / 2 + 1, 9 * ratio + 1);
    context.restore();
};
function isAvailable$1() {
    if (!original) {
        original = getFavicon();
        image = document.createElement('img');
        canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        context = canvas.getContext ? canvas.getContext('2d') : null;
    }
    return !!context && !!original;
}
function set$1(value, options) {
    // Remember options
    current$1.value = value;
    deepMerge(current$1.options, options || {});
    if (!isAvailable$1()) {
        return false;
    }
    // Draw icon
    drawFavicon(current$1.value, current$1.options);
    return true;
}
function clear$1() {
    if (!isAvailable$1()) {
        return;
    }
    setFavicon(original.href);
}

const defaultOptions$1 = {
    indicator: '!',
};
const current$2 = {
    title: null,
    value: 0,
    options: defaultOptions$1,
};
function changeTitle(title, value, options) {
    let newTitle = title;
    if (isPositiveNumber(value)) {
        if (value === 0) {
            newTitle = title;
        }
        else {
            newTitle = `(${value}) ${title}`;
        }
    }
    else {
        newTitle = `(${options.indicator}) ${title}`;
    }
    const element = document.querySelector('title');
    if (element) {
        element.childNodes[0].nodeValue = newTitle;
    }
}
function set$2(value, options) {
    if (current$2.title === null) {
        current$2.title = document.title;
        // Watch changes of title
        Object.defineProperty(document, 'title', {
            get: () => {
                return current$2.title;
            },
            set: title => {
                current$2.title = title;
                changeTitle(current$2.title, current$2.value, current$2.options);
            },
        });
    }
    // Remember value and options
    current$2.value = value;
    deepMerge(current$2.options, options || {});
    // Trigger change
    document.title = document.title;
    return true;
}
function clear$2() {
    current$2.value = 0;
    // Trigger change
    document.title = document.title;
}

/**
 * Sets badge
 */
function set$3(value, options = {}) {
    switch (options.method) {
        case undefined:
        case 'Badging': {
            if (set(value)) {
                // Break only if method is explicitly requested
                if (options.method === 'Badging') {
                    break;
                }
            }
        }
        case 'Favicon': {
            if (set$1(value, options.favicon)) {
                break;
            }
        }
        default: {
            set$2(value, options.title);
        }
    }
}
/**
 * Clears badge
 */
function clear$3() {
    clear();
    clear$1();
    clear$2();
}

export { clear$3 as clear, set$3 as set };
